import React from "react"
import BackgroundImage from "gatsby-background-image"

import Button from "../../UI/Button/button.js"

// eslint-disable-next-line no-unused-vars
import style from "./style.scss"

const CTA = ({ image, text, buttonText }) => {
  return (
    <BackgroundImage
      className="section section--cta"
      fluid={image.localFile.childImageSharp.fluid}
    >
      <div className="wrapper">
        <h2 className="cta-text">{text}</h2>
        <Button to="/talk-to-us">{buttonText}</Button>
      </div>
    </BackgroundImage>
  )
}

export default CTA
